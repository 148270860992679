.c-banner1{
    background-image: url('../../../assets/images/Banner-0.png');
}
.c-banner2{
    background-image: url('../../../assets/images/Banner-2.jpg');
}
.c-banner3{
    background-image: url('../../../assets/images/Banner-1.jpg');
}
.c-banner4{
    background-image: url('../../../assets/images/library3.jpg');
}
.c-banner1, .c-banner2, .c-banner3, .c-banner4{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}