.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

.page-header{
    background-image: url("./assets/images/header5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}
